/* Chat bubble */
.chatPopupWrapper{
    position: fixed;
    bottom: 50px;
    right: 50px;
    cursor: pointer;
    display: flex;
    background-color: white;
    border-radius: 10px;
    padding: 10px 15px;
    box-shadow: 0 2px 4px rgba(0.5, 0.5, 0.5, 0.5);
    border: 0.2px solid #23B4C6;
}

/* Chat form */
.formWrapper{
  display: flex;
  flex-direction: column;
  padding: 3rem;
  height: 87%;
  overflow: auto;
}

.header{
  display: flex;
  justify-content: space-between;
}

.modalTitle {
  font-size: 1.8rem;
  font-weight: 400;
  color: #0d0d0d;
}

.collapseIcon {
  cursor: pointer;
}

.chatContainer{
  display: flex;
  flex-direction: column-reverse;
  margin-top: 1rem;
  padding: 0 2rem;
  overflow: auto;
}

/* Chat message container */
.chatMessage {
  margin: 10px 0;
  padding: 8px 12px;
  border-radius: 20px;
  background-color: #f0f0f0;
  max-width: 80%;
  }
  
  /* Sender's chat message */
  .senderMessage {
    background-color: #23B4C6; /* Light green */
    align-self: flex-end;
  }
  
  /* Receiver's chat message */
  .receiverMessage {
    background-color: whitesmoke; /* White */
    align-self: flex-start;
  }
  
  /* Chat message text */
  .messageText {
    margin: 0;
    font-size: 16px;
    color: #333;
  }
  
  .senderMessageText{
    margin: 0;
    font-size: 16px;
    color: white;
  }

  /* Timestamp for chat message */
  .messageTimestamp {
    font-size: 12px;
    color: #888;
    float: right;
    margin-top: 1rem;
  }
  .senderMessageTimestamp {
    color: white;
  }

  .loaderWrapper {
    text-align: center;
  }
  .chatInput{ 
    position: fixed !important;
    bottom: 5%;
    width: 94% ;
    left: 3%;
  }