@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.4rem;
  color: #363636;
}

.active-modal {
  overflow: hidden;
}

.alignCenter {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.modalBtnCont {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.modalChild {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
}

.popupTextStyle {
  color: #23b4c6;
  font-weight: 300;
}

.loaderCont {
  height: 100vh;
}

.errorMsg {
  color: #ff4141;
}

.tooltipIcon {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
}

::-webkit-scrollbar-track {
  background: #fdfdfd;
}

::-webkit-scrollbar-thumb {
  background: #065e87;
}

::-webkit-scrollbar-thumb:hover {
  background: #054867;
}

.dropdown::-webkit-scrollbar {
  width: 0.6rem;
  height: 1rem;
}

/* Color variations on fonts or any BG */
.logoFontGradiant {
  background: var(--primary-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Color variables */
:root {
  --primary-color: "";
  --secondary-color: "";

  --primary-gradient: linear-gradient(
    90deg,
    #0aa7fb 48.5%,
    #03cfdd 63.68%,
    #01e0d0 70.66%
  );
  --secondary-gradient: linear-gradient(
    90deg,
    #082d68 0%,
    #003f75 26.24%,
    #016d8d 65.62%,
    #067793 100%
  );
}

.dottedBackground {
  --dot-bg: #f5f5f5;
  --dot-color: #cccccc;
  --dot-size: 2px;
  --dot-space: 16px;
  background:
    linear-gradient(
        90deg,
        var(--dot-bg) calc(var(--dot-space) - var(--dot-size)),
        transparent 1%
      )
      center / var(--dot-space) var(--dot-space),
    linear-gradient(
        var(--dot-bg) calc(var(--dot-space) - var(--dot-size)),
        transparent 1%
      )
      center / var(--dot-space) var(--dot-space),
    var(--dot-color);
}
