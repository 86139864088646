.buttonDefault {
  border: none;
  padding: 0 1.4rem;
  border-radius: 0.3rem;
  cursor: pointer;
  color: #ffffff;
  background-color: #23b4c6;
  font-weight: 500;
  font-size: 1.6rem;
  text-transform: capitalize;
  height: 3.6rem;
}

.buttonDanger {
  padding: 0 1.4rem;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  color: #ffffff;
  background-color: #dd5246;
  font-weight: 500;
  font-size: 1.6rem;
  height: 3.6rem;
  text-transform: capitalize;
}

.buttonCancel {
  padding: 0 1.4rem;
  border: 1px solid #9cdde5;
  border-radius: 0.3rem;
  cursor: pointer;
  color: #23b4c6;
  background-color: #ffffff;
  font-weight: 500;
  font-size: 1.6rem;
  text-transform: capitalize;
  height: 3.6rem;
}

.buttonLowKey {
  padding: 0.6rem 1.2rem;
  border: 1px solid #94dbe4;
  border-radius: 0.3rem;
  cursor: pointer;
  background-color: #ffffff;
  text-transform: capitalize;
  color: #23b4c6;
}

.buttonToggle {
  padding: 0 1rem;
  border: 1px solid #dadde2;
  border-radius: 0.3rem;
  cursor: pointer;
  color: #23b4c6;
  background-color: #fdfdfd;
  text-transform: capitalize;
  height: 3.6rem;
}

.disabled {
  cursor: not-allowed;
  filter: opacity(0.5);
}

.buttonTransparent {
  cursor: pointer;
  height: 3.6rem;
  padding: 0 1.4rem;
  text-transform: capitalize;
  font-size: 1.6rem;
  font-weight: 500;
  color: #23b4c6;
  border: 1px solid #23b4c6;
  border-radius: 10rem;
  background-color: #ffffff;
}
