.container {
  position: fixed;
  z-index: 9999;
  bottom: 1rem;
  left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 32rem;
}

.subCont {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexCol {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.notification {
  border-radius: 0.3rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s ease;
  animation: toast-in-left 0.6s;
  padding: 1.4rem;
  border: 1px solid;
}

.notification:hover {
  box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.2);
}

.title {
  font-weight: 600;
}

.btnContainer {
  width: 32rem;
  display: flex;
  justify-content: center;
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
