.containerOpen {
  margin-left: 22rem;
  transition: all 0.2s ease-out;
}

.containerClose {
  margin-left: 8.2rem;
  transition: all 0.2s ease-out;
}

.sidebarOpen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 22rem;
  background: linear-gradient(
    178deg,
    #082c66 0.1%,
    #003d73 47.98%,
    #026e8e 79.33%,
    #067591 100%
  );
  filter: drop-shadow(1.5px 0px 4px rgba(0, 0, 0, 0.15));
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  transition: all 0.2s ease-out;
  padding: 1.6rem 0rem;
}

.sidebarOpen::-webkit-scrollbar {
  display: none;
}

.sidebarClose {
  width: 8.2rem;
}

.logoCont {
  margin-bottom: 1.8rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  justify-content: center;
  height: 5rem;
}

.logoImg {
  width: 4rem;
}

.logoText {
  height: 2.4rem;
}

.linkCont {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  overflow-y: scroll;
  margin-bottom: 1.4rem;
}

.bottomLinksCont {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin-bottom: 2rem;
  margin-top: auto;
  box-shadow: -2px -8px 8px rgba(0, 0, 0, 0.2);
}

.linkCont::-webkit-scrollbar {
  display: none;
}

.navLinksContOpen {
  display: flex;
  gap: 1.8rem;
  align-items: center;
  padding: 1.5rem 2.4rem;
  transition: all 0.3s ease-out;
  height: 4.6rem;
}

.navLinksContClose {
  padding: 1.6rem 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.6rem;
}

.navLinksContOpen:hover,
.navLinksContClose:hover {
  cursor: pointer;
  transition: all 0.3s ease-out;
  background-color: rgba(255, 255, 255, 0.1);
}

.linkIcon,
.logoutCont > img {
  width: 2.4rem;
  height: 2.4rem;
}

.linkActive {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: inset -3px 0px 0 rgba(255, 255, 255, 1);
}

.link {
  font-size: 1.8rem;
  color: #fff;
  font-weight: 300;
}

.userActions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1.8rem;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.rotate {
  transform: rotate(180deg);
  transition: all 0.3s ease-out;
}

.userOuterCont {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: auto;
}

.toggleBtn {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  cursor: pointer;
}

.divider {
  padding: 0 1.8rem;
}

.divider div {
  background-color: #ffffff;
  width: 100%;
  height: 1px;
}

.userCont {
  display: flex;
  align-items: center;
  padding: 1rem 1.8rem;
  gap: 1.4rem;
  cursor: pointer;
}

.sidebarClose .userCont {
  justify-content: center;
  margin-top: 1.6rem;
}

.userCont:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease-out;
}

.userIcon {
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  border: 3px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
  background: linear-gradient(180deg, #09a9f9 0%, #04cbe0 49.94%, #00e3ce 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.userNameCont {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  color: #fff;
  font-size: 1.4rem;
}

.userName,
.logoutCont span {
  color: #fff;
  font-size: 1.4rem;
}

.userEmail {
  font-size: 1.2rem;
  font-weight: 300;
  max-width: 12rem;
  word-wrap: break-word;
}

.logoutCont {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1rem 2.4rem;
}

.sidebarClose .logoutCont {
  justify-content: center;
}

.logoutCont:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.beaconIcon {
  position: absolute;
  top: -0.4rem;
  right: 1.2rem;
}
