.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - 6rem);
  height: calc(100vh - 6rem);
  overflow: auto;
  outline: none;
  background-color: #fff;
  z-index: 14;
}
